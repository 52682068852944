import {
  computePosition,
  offset,
  flip,
  shift,
  autoUpdate,
  hide,
  type ComputePositionConfig,
} from "@floating-ui/dom";

const DEFAULT_MIDDLEWARE = [offset(8), flip(), shift({ padding: 8 }), hide({ padding: 12 })];

function updatePopoverPosition(
  trigger: HTMLElement,
  popover: HTMLElement,
  config?: Partial<ComputePositionConfig>,
) {
  computePosition(trigger, popover, {
    placement: "bottom-start",
    strategy: "fixed",
    middleware: DEFAULT_MIDDLEWARE,
    ...config,
  }).then(({ x, y, middlewareData }) => {
    if (middlewareData.hide?.referenceHidden) {
      return;
    }

    Object.assign(popover.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
  });
}

/** Creates a popover and returns a cleanup function. */
export function createPopover(options: {
  trigger: HTMLElement;
  popover: HTMLElement;
  config?: Partial<ComputePositionConfig>;
}) {
  return autoUpdate(options.trigger, options.popover, () =>
    updatePopoverPosition(options.trigger, options.popover, options.config),
  );
}

/** Like `createPopover` but with clickOutside */
export function createPopoverWithClickOutside(options: {
  trigger: HTMLElement;
  popover: HTMLElement;
  onClickOutside: () => void;
  config?: Partial<ComputePositionConfig>;
}) {
  const cleanupUpdate = autoUpdate(options.trigger, options.popover, () =>
    updatePopoverPosition(options.trigger, options.popover, options.config),
  );

  const clickCallback = ({ target }: Event) => {
    if (!options.popover.contains(target as HTMLElement)) {
      options.onClickOutside();
    }
  };
  // Use the capture phase so as to be able to compute the contains() before the element
  // may be removed.
  document.addEventListener("click", clickCallback, true);

  return () => {
    document.removeEventListener("click", clickCallback, true);
    cleanupUpdate();
  };
}
