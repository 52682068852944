import { createUniqueId, onMount, Show, type ComponentProps } from "solid-js";
import Trix from "trix";
import "./index.css";

Trix.config.toolbar.getDefaultHTML = () => `
<div class="inline-grid grid-flow-col auto-cols-fr">
  <button type="button" class="font-bold" data-trix-attribute="bold" aria-label="Bold" tabindex="-1">B</button>
  <button type="button" class="italic" data-trix-attribute="italic" aria-label="Italics" tabindex="-1">I</button>
  <button type="button" data-trix-attribute="bullet" aria-label="Bulleted list" tabindex="-1">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
    </svg>
  </button>
</div>`;

document.addEventListener("trix-file-accept", (event) => {
  // We don't do file attachments...
  event.preventDefault();
});

function ResetableText(props: {
  editorId: string;
  inputId: string;
  autofocus?: boolean;
  ref: ComponentProps<"input">["ref"];
  initial: true | { value: string };
}) {
  let editorRef: HTMLElement | undefined;
  // eslint-disable-next-line solid/reactivity
  if (props.autofocus) {
    onMount(() => {
      editorRef!.focus();
    });
  }
  // This wrapper div gives a clear owner/teardown
  return (
    <div>
      <trix-editor ref={editorRef} attr:input={props.inputId} id={props.editorId} />
      <input
        ref={(elem) => {
          if (props.initial !== true) {
            elem.value = props.initial.value;
          }
          (props.ref as (e: typeof elem) => void)(elem);
        }}
        type="hidden"
        id={props.inputId}
      />
    </div>
  );
}

export function RichTextEditor(props: {
  // This value object may seem like unneed overhead but just incase two different
  // keyed items have the same content, this API is more likely to not be wrong.
  initial?: { value: string } | null;
  autofocus?: boolean;
  id: string;
  ref?: ComponentProps<"input">["ref"];
}) {
  const inputId = createUniqueId();
  return (
    <Show keyed when={props.initial || true}>
      {(initial) => (
        <ResetableText
          ref={props.ref}
          initial={initial}
          inputId={inputId}
          editorId={props.id}
          autofocus={props.autofocus}
        />
      )}
    </Show>
  );
}
