function rawFormatDate(
  date: Date | string | null,
  fallback: string,
  options?: Intl.DateTimeFormatOptions,
): string {
  if (!date) {
    return fallback;
  }
  date = typeof date === "string" ? new Date(date) : date;
  return date.toLocaleDateString(undefined, options);
}

export function formatLongDate(date: Date | string | null, fallback = ""): string {
  return rawFormatDate(date, fallback, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

function formatTime(time: Date | string) {
  time = typeof time === "string" ? new Date(time) : time;
  return time.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "2-digit",
  });
}

export function formatAppointmentTimeSpan(
  appointment: {
    endTime: string | null;
    startTime: string | null;
  },
  fallback = "",
): string {
  const { startTime, endTime } = appointment;
  if (startTime && endTime) {
    return `${formatTime(startTime)} - ${formatTime(endTime)}`;
  } else if (startTime) {
    return formatTime(startTime);
  }
  return fallback;
}

export function formatDate(date: Date | string | null, fallback = ""): string {
  return rawFormatDate(date, fallback);
}

export function formatPhoneNumber(number: string | null, fallback = ""): string {
  if (!number) {
    return fallback;
  }
  const match = number.replace(/\D/g, "").match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : number;
}

export function formatUSD(value: string | number, options?: { round?: boolean }): string {
  const numericValue = typeof value === "string" ? parseFloat(value) : value;
  return `$${numericValue.toFixed(options?.round ? 0 : 2)}`;
}
